import Button from "@src/components/Button"

import StoryblokClient, { type Richtext } from "storyblok-js-client"

interface Props {
  buttonText: string
  buttonUrl: string
  cardContent: Richtext
  cardImage?: {
    alt?: string | null
    filename?: string | null
  } | null
  cardTitle: string
}
export default function ProductCardMarketing({
  buttonText,
  buttonUrl,
  cardContent,
  cardImage,
  cardTitle,
}: Props) {
  return (
    <div
      className={[
        "group flex flex-col justify-start transition-colors border border-transparent rounded-sm hover:border-gray-300",
      ].join(" ")}
      data-component="product-card-marketing"
    >
      <a
        href={buttonUrl}
        aria-label={cardTitle}
        className="bg-center bg-cover block relative before:content-[''] before:block before:pt-[100%]"
        style={{
          backgroundImage: `url(${
            cardImage?.filename || "/images/placeholder.jpeg"
          })`,
        }}
      >
        <div
          className="bg-center bg-cover bottom-0 h-full left-0 absolute opacity-0 right-0 top-0 transition-all w-full group-hover:opacity-100 z-[1]"
          style={{
            backgroundImage: `url(${
              cardImage?.filename || "/images/placeholder.jpeg"
            })`,
          }}
        />
      </a>
      <div className="px-4 md:flex md:flex-col grow p-4">
        <h3>
          <a
            className="font-extrabold text-2xl mb-1 uppercase"
            dangerouslySetInnerHTML={{
              __html: cardTitle,
            }}
            href={buttonUrl}
          />
        </h3>
        <p
          className="font-serif text-base italic tracking-wide mb-4"
          dangerouslySetInnerHTML={{
            __html: new StoryblokClient({}).richTextResolver.render(
              cardContent
            ),
          }}
        />
        <div>
          <Button
            className={["w-full"]}
            size="small"
            text={buttonText}
            type="borderBlack"
            url={buttonUrl}
          />
        </div>
      </div>
    </div>
  )
}
