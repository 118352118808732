export const colorMap: Record<string, string> = {
  aquaBlue: "#87d1d4",
  blueSky: "#6aaae4",
  coral: "#ee907b",
  deepRose: "#c7868d",
  forestGreen: "#3d5b58",
  goldenYellow: "#fcd672",
  greenXlt: "#f0f5f3",
  greyMd: "#686868",
  greyLt: "#e7e7e7",
  onyxBlack: "#24272a",
  mintIsh: "#6cca98",
  mossGreen: "#99bcab",
  paleRose: "#eaccce",
  pinkOrange: "#ffa168",
  red: "#de5642",
  redOrange: "#ea725e",
  springGreen: "#c2e76b",
}

export const colors = Object.values(colorMap)

export function randomInRange(min: number, max: number): number {
  return Math.random() * (max - min) + min
}

export function getAnimationSettings(originXA: number, originXB: number) {
  return {
    startVelocity: 30,
    spread: 360,
    ticks: 60,
    zIndex: 0,
    particleCount: 150,
    origin: {
      x: randomInRange(originXA, originXB),
      y: Math.random() - 0.2,
    },
  }
}
