import BundleCard from "@src/components/BundleCard"
import ProductCard from "@src/components/ProductCard"
import ProductCardMarketing from "@src/components/ProductCardMarketing"
import getSiteUrl from "@src/utilities/get-site-url"

import type { BundleProductCard } from "@src/entities/models/bundle-product-card"

export interface ProductGridProps {
  content: {
    container_width?: {
      containerWidth?: string
    }
    headline?: string
    preheadline?: string
    product_cards?: BundleProductCard[]
    _uid?: string
  }
}
export default function ProductGrid({ content }: ProductGridProps) {
  return (
    <div
      className={`container-${
        content.container_width?.containerWidth ?? "website"
      }`}
    >
      <div className="w-full">
        {content.preheadline && content.preheadline.length > 0 && (
          <p
            className="italic font-serif mb-2 text-lg text-center"
            dangerouslySetInnerHTML={{ __html: content.preheadline }}
          />
        )}
        {content.headline && content.headline.length > 0 && (
          <h2
            className="mb-8 font-extrabold text-center text-3xl md:text-4xl uppercase"
            dangerouslySetInnerHTML={{ __html: content.headline }}
          />
        )}
        <div className="mb-4 md:mb-8 md:grid md:place-content-center md:gap-8 md:place-content-center md:grid-cols-2 lg:mb-16 lg:grid-cols-3 xl:grid-cols-4">
          {content.product_cards?.map((card, idx) => {
            if (card.component === "marketing_product_card") {
              return (
                <ProductCardMarketing
                  cardContent={card.card_content}
                  cardTitle={card.card_title}
                  buttonText={card.button_text}
                  buttonUrl={card.button_url}
                  cardImage={{
                    alt: card.card_image?.alt,
                    filename: card.card_image?.filename,
                  }}
                  key={`grid-${card._uid}-${idx}`}
                />
              )
            }
            if (card.component === "product_card") {
              if (!card.product) {
                if (process.env.NODE_ENV !== "production") {
                  console.warn("Missing product for card", card)
                }
                return null
              }
              return (
                <ProductCard
                  key={`grid-${content._uid}-${idx}`}
                  product={card.product}
                  url={
                    card?.link_to_product_page?.cached_url &&
                    `${getSiteUrl()}/${card.link_to_product_page.cached_url}`
                  }
                />
              )
            }
            return (
              <BundleCard
                key={`grid-${content._uid}-${idx}`}
                bundle={card.bundle}
                storyblokPageId={card.link_to_bundle_page?.id}
                url={
                  card?.link_to_bundle_page?.cached_url &&
                  `${getSiteUrl()}/${card.link_to_bundle_page.cached_url}`
                }
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
