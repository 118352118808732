import { useEffect, useState } from "react"
import { HiArrowTopRightOnSquare } from "react-icons/hi2"
import ReactCanvasConfetti from "react-canvas-confetti"

import Button from "@src/components/Button"
import QuantitySelector from "@src/components/QuantitySelector"

import { useAppDispatch, useAppSelector } from "@src/hooks/use-redux"

import { colors } from "@src/utilities/confetti"
import { formatCurrency } from "@src/utilities/format-numbers"
import { setCartCount } from "@src/redux/cart.slice"
import { getUser } from "@src/redux/user.slice"

import { addProductToCart } from "@src/workers/api/add-to-cart"

import type { BundleProductCard } from "@src/entities/models/bundle-product-card"

export interface ProductCardWholesaleProps {
  cardType: "link_to_page" | "add_to_cart"
  minQuantity: number
  product?: BundleProductCard["product"]
  sectionColor: string | null
  url?: string
}
export default function ProductCardWholesale({
  cardType,
  minQuantity,
  product,
  sectionColor,
  url,
}: ProductCardWholesaleProps) {
  if (!product) {
    return null
  }
  const dispatch = useAppDispatch()
  const user = useAppSelector(getUser)
  const [addingToBag, setAddingToBag] = useState<boolean>(false)
  const [quantity, setQuantity] = useState(minQuantity)

  const addToCart = async (qty: number) => {
    setAddingToBag(true)
    const { cartCount, errors } = await addProductToCart({
      sku: product.sku,
      quantity: qty,
    })
    if (errors) {
      errors.forEach((error) => {
        console.error(error)
      })
      setAddingToBag(false)
      return
    }
    dispatch(setCartCount(cartCount))
    setAddingToBag(false)
  }

  useEffect(() => {}, [quantity])

  const description = product?.description ?? "Description not set"

  return (
    <div
      className={[
        "group flex flex-col justify-start transition-colors border border-transparent rounded-sm hover:border-gray-300",
      ].join(" ")}
      data-component="product-card-wholesale"
    >
      <a
        href={url}
        aria-label={product?.name ?? ""}
        className="bg-center bg-cover block relative before:content-[''] before:block before:pt-[100%] lg:w-4/5"
        style={{
          backgroundImage: `url(${
            product?.thumbnailUrl || "/images/placeholder.jpeg"
          })`,
        }}
        target={"_blank"}
      />
      <div className="px-4 md:flex md:flex-col grow p-4">
        <h3>
          <a
            className="font-extrabold text-2xl mb-1 uppercase"
            dangerouslySetInnerHTML={{
              __html: product?.name ?? "Name not set",
            }}
            href={url}
            target="_blank"
          />
        </h3>
        <p
          className="font-serif text-base italic tracking-wide m2-4"
          dangerouslySetInnerHTML={{
            __html: `${description} (Qty: ${minQuantity})`,
          }}
        />
        <div
          className="font-medium my-2 px-2 pt-2 pb-1 text-sm"
          style={{
            ...(sectionColor && { backgroundColor: sectionColor }),
          }}
        >
          MSRP: {formatCurrency(product.priceRetail)}ea.
        </div>
        {cardType === "add_to_cart" && (
          <>
            <div className="max-w-[140px] mb-2">
              <QuantitySelector
                quantity={quantity}
                onSetQuantity={setQuantity}
              />
            </div>
            <Button
              disabled={addingToBag || !user.authStateKnown}
              onClick={() => addToCart(quantity)}
              size="small"
              type="transparentBlack"
              text={addingToBag ? "Adding to cart" : "Add to cart"}
            />
          </>
        )}
        {cardType === "link_to_page" && (
          <a
            href={url}
            className="flex justify-between align-center"
            target={"_blank"}
          >
            <span className="font-serif">Full product details</span>
            <HiArrowTopRightOnSquare className="inline-block leading-none" />
          </a>
        )}
      </div>
      <ReactCanvasConfetti
        className="fixed h-full left-0 pointer-events-none top-0 w-full z-[999999]"
        colors={colors}
        fire={!addingToBag}
        particleCount={150}
        spread={360}
        startVelocity={30}
        ticks={360}
        zIndex={0}
      />
    </div>
  )
}
