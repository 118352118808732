import { BsDashCircle, BsPlusCircle } from "react-icons/bs"

import type { Dispatch, SetStateAction } from "react"

interface Props {
  onSetQuantity: Dispatch<SetStateAction<number>>
  quantity: number
}
export default function QuantitySelector({ onSetQuantity, quantity }: Props) {
  return (
    <div className="flex items-center space-x-2">
      <button
        disabled={quantity === 1}
        className="text-black disabled:opacity-50"
        onClick={() => {
          onSetQuantity((prevState) => prevState - 1)
        }}
      >
        <BsDashCircle className="h-6 w-6" />
      </button>
      <input
        className="px-2 py-2 border border-gray-400 grow text-sm focus:outline-none rounded-md w-full"
        onChange={(ev) => onSetQuantity(Number(ev.target.value))}
        type="number"
        step={1}
        value={quantity}
      />
      <button
        className="text-black"
        onClick={() => {
          onSetQuantity((prevState) => prevState + 1)
        }}
      >
        <BsPlusCircle className="h-6 w-6" />
      </button>
    </div>
  )
}
