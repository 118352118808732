import apolloClient from "@src/utilities/apollo-client"

import {
  ADD_PRODUCT_TO_CART,
  ADD_PRODUCTS_TO_CART,
} from "@src/operations/api/cart"

import type { GraphQLErrors } from "@apollo/client/errors"
import type {
  CartAddInput,
  AddProductToCartMutation,
  AddProductsToCartMutation,
  AddProductToCartMutationVariables,
  AddProductsToCartMutationVariables,
} from "@src/entities/types/api"

interface AddToCartResponse {
  cartCount: number
  errors: GraphQLErrors | null
}
export async function addProductToCart(
  product: CartAddInput
): Promise<AddToCartResponse> {
  const { data, errors } = await apolloClient.mutate<
    AddProductToCartMutation,
    AddProductToCartMutationVariables
  >({
    mutation: ADD_PRODUCT_TO_CART,
    variables: { input: product },
  })

  if (errors) {
    return {
      cartCount: 0,
      errors,
    }
  }

  return {
    cartCount: data?.addProductToCart.cartCount ?? 0,
    errors: null,
  }
}

export async function addProductsToCart(
  products: CartAddInput[]
): Promise<AddToCartResponse> {
  const { data, errors } = await apolloClient.mutate<
    AddProductsToCartMutation,
    AddProductsToCartMutationVariables
  >({
    mutation: ADD_PRODUCTS_TO_CART,
    variables: { input: products },
  })

  if (errors) {
    return {
      cartCount: 0,
      errors,
    }
  }

  return {
    cartCount: data?.addProductsToCart.cartCount ?? 0,
    errors: null,
  }
}
